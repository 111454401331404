<template>
    <v-app>
        <NavBar style="max-height: 50px; margin-bottom: 10px" />
        <div class="row">
            <div class="col-xs-3 col-sm-3 col-md-3 col-lg-2">
                <SideBar style="max-height: 50px" :pageName="pageName"/>
            </div>
            <div class="col-xs-9 col-sm-9 col-md-9 col-lg-10">
                <div class="scrollDiv">
                    <div class="flexRowBetween my-6" style="height: 48px;">
                        <span class="commonHomeFont">
                            User Management
                        </span>
                    </div>
                    <div class="UserManagementInnerClass">
                        <div class="UserValidationFontClass">User Validation</div>
                        <div class="d-flex flex-row" style="width:100%; justify-content: space-between;">
                            <div class="MobileNumberClass">
                                <div class="ml-2" style="margin-bottom: 5px; margin-top: 5px;">
                                    <img src="https://s3iconimages.mymedicine.com.mm/myanmar_flag.svg" align="left" style="width:26px ;height:26px;" />
                                </div>
                                <div class="ml-2" style="margin-bottom: 6px; margin-top: 4px;">+95</div>
                                <div class="ml-2" style=" margin-top: 5px;" >
                                    <v-text-field 
                                    background-color="#F4F6FA" 
                                    v-model="mobileNumber" 
                                    :rules="mobileNumberRules" placeholder="09*********" />
                                </div>
                            </div>
                            <div v-if="!userExistMessageFlag" class="ValidateUserClass" @click="validatePhoneNumber()" style="cursor: pointer;">
                                <span class="ValidateUserFontClass">
                                    Validate User
                                </span>
                            </div>
                        </div>
                        <div v-if="userExistMessageFlag"  class="d-flex flex-row" style="width:100%;">
                            <div class="d-flex flex-row" style="width:100%; justify-content: flex-start; align-items: center">
                                <img width="20px" height="20px" v-if="userExistFlag" :src="userExistIcon" />
                                <img width="20px" height="20px" v-else :src="noUserExistIcon" />
                                <span class="ml-4" :class="userExistFlag ? 'userExistClass' : 'noUserExistClass'">
                                    {{ userExistText }}
                                </span>
                            </div>
                            <div class="d-flex flex-row" style="width:50%; justify-content: space-between;">
                                <div class="d-flex flex-row retryClass" @click="retryReset()" style="width:49%;cursor: pointer;">
                                    <img :src="retryGrey" />
                                    <span class="retryFontClass">
                                        Retry
                                    </span>
                                </div>
                                <div class="createNewUserClass" @click="goToCreateNewUserPage()" style="width:49%;cursor: pointer;">
                                    <span class="createNewUserFontClass">
                                        {{ createNewUserText }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="isDataLoading" class="d-flex justify-center align-center">
                            <v-progress-circular size="30" indeterminate color="primary"></v-progress-circular>
                        </div>
                    </div>
                    
                    <div v-if="confirmationFlag">
                        <DeleteConfirmationDialog :confirmationFlag="confirmationFlag" :itemType="itemType" @closeDialog="closeDialog" />
                    </div>
                    <div v-if="showMessageDialog">
                        <SuccessDialog :successObject="successObject" @closeSuccessDialog="closeSuccessDialog"/>
                    </div>
                </div>
            </div>
        </div>
    </v-app>
</template>

<script>
import axios from "axios";
import NavBar from '../../navbar.vue';
import SideBar from '../../sideBar.vue';
import DeleteConfirmationDialog from "../../../components/DeleteConfirmationDialog.vue";
import SuccessDialog from "../../../components/SuccessDialog.vue";
export default {
name: "UserManagement",
components : {
    'NavBar': NavBar,
    'SideBar': SideBar,
    'DeleteConfirmationDialog': DeleteConfirmationDialog,
    'SuccessDialog': SuccessDialog,
},
computed: {
},
data() {
    return {
        userExistText: '',
        pageName: 'UserManagement',
        mobileNumberRules: [
            v => !!v || 'Mobile Number is required',
            v => (v && v.length <= 11 && v.length >=7) || 'Mobile Number should be between 7 to 11 numbers',
            v => v > 0 || 'Mobile number cannot be negative',
        ],
        successObject: {},
        showMessageDialog: false,
        itemType:'',
        iconObjects: [],
        segmentCacheData: [],
        promotionBanners: [],
        showPreviewOnBuilder: false,
        previewOnBuilderProps: {},
        bannerEditData: {},
        editBlockDetails: {},
        confirmationFlag: false,
        getBannerSegmentResponseData: {},
        addHeroBannerDialogFlag: false,
        cloudFront: process.env.VUE_APP_CLOUDFRONT_URL_FOR_IMAGES,
        editIcon: 'editIcon.svg',
        deleteIcon: 'deleteIcon.svg',
        userExistIcon: 'userExist.svg',
        noUserExistIcon: 'noUserExist.svg',
        retryGrey: 'retryGrey.svg',
        draggableIcon: 'draggableIcon.svg',
        noSegmentImage: 'noSegment.svg',
        addBanner: 'addBanner.svg',
        finalEditFlag: false,
        editHeroBannerDialogFlag: false,
        finalEditValue: {},
        multipleAdds: 'multipleAdds.svg',
        addBlock: 'addBlock.svg',
        previewBanner: 'previewBanner.svg',
        addArticles: 'addArticles.svg',
        isHeroBannerAvailable: false,
        isSegmentAvailable: true,
        editFlag: false,
        showPromotionBanners: false,
        showCommonDraggableIcon: false,
        showBlock: false,
        showArticles: false,
        bannerTypes: ['Carousal', 'Single'],
        bannerType: '',
        headerColor: `#F4F6FA !important`,
        heroBannerDetails: [],
        customeSlotName: [],
        isDataLoading: false,
        mobileNumber: '',
        userExistMessageFlag: false,
        userExistFlag: false,
        createNewUserText: '',
    }
},
methods: {
    async validatePhoneNumber() {
        this.isDataLoading = true;
        try {
            let validatePhoneNumberResponse = await axios.post(process.env.VUE_APP_BACKEND_URL + "/validateUserPhoneNumber", {
                phoneNumber: this.mobileNumber.toString()
            });
            if(validatePhoneNumberResponse && validatePhoneNumberResponse.data && validatePhoneNumberResponse.data.data) {
                if(validatePhoneNumberResponse.data.data === 'EXISTS') {
                    this.userExistFlag = true;
                    this.userExistText = '1 user linked';
                    this.createNewUserText = 'Edit User Details';
                } else {
                    this.userExistFlag = false;
                    this.userExistText = 'User does not exist';
                    this.createNewUserText = 'Create New User';
                }
                this.userExistMessageFlag = true;
            }  
            this.isDataLoading = false;    
        } catch (error) {
            if(error.response && error.response.status === 305){
                this.userExistFlag = true;
                this.userExistText = ' user linked';
                this.createNewUserText = 'Edit User Details';
                this.userExistMessageFlag = true;
            }else{
            console.error('Error validation phone number',error)
            }
            this.isDataLoading = false;
        }
    },
    async retryReset() {
        this.userExistMessageFlag = false;
        this.mobileNumber = '';

    },
    async goToCreateNewUserPage() {
        this.$router.push({
            name: "CreateNewUserPage",
            query: {
                params: this.mobileNumber
            }
        })
    },
    closeSuccessDialog() {
        this.showMessageDialog = false;
    },
    refreshPage() {
        window.location.reload();
    },
    closeDialog (valueFromEmit) {
        this.confirmationFlag = false;
    },
},
async mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
        this.$router.push({
        name: "AdminLogin",
        });
    } else {
        this.userExistIcon = this.cloudFront + this.userExistIcon;
        this.noUserExistIcon = this.cloudFront + this.noUserExistIcon;
        this.previewBanner = this.cloudFront + this.previewBanner;
        this.retryGrey = this.cloudFront + this.retryGrey;
        this.noSegmentImage = this.cloudFront + this.noSegmentImage;
        this.multipleAdds = this.cloudFront + this.multipleAdds;
        this.addBanner = this.cloudFront + this.addBanner;
        this.addBlock = this.cloudFront + this.addBlock;
        this.addArticles = this.cloudFront + this.addArticles;
        this.draggableIcon =  this.cloudFront + this.draggableIcon;
        this.editIcon = this.cloudFront + this.editIcon;
        this.deleteIcon = this.cloudFront + this.deleteIcon;
    }
},
}
</script>

<style>
.addNewSegmentsDiv {
display: flex;
flex-direction: row;
justify-content: space-between;
}
.scrollDiv {
max-height: 900px;
overflow-y: scroll;
}
.dot {
display: flex;
align-items: center;
width: 80px;
height: 80px;
background-color: #000;
border-radius: 50%;
margin: 10px;
cursor: grab;
color: #fff;
}

.dot-handle {
width: 20px;
height: 20px;
background-color: #fff;
border-radius: 50%;
margin-right: 10px;
cursor: grab;
}
.draggable-item {
background-color: #f2f2f2;
border: 1px solid #ddd;
padding: 10px;
margin-bottom: 5px;
cursor: grab;
width: 100%;
}

.flexRowBetween {
display: flex;
flex-direction: row;
justify-content: space-between;
}
.commonHomeFont{
height: 34px;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 28px;
line-height: 34px;
color: #333333;
flex: none;
order: 0;
flex-grow: 0;
}
.UserManagementInnerClass {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    gap: 32px;
    isolation: isolate;
    width: 99%;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 12px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}
.ValidateUserClass {
    display: flex;
    width: 240px;
    height: 48px;
    padding: 8px 56px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex-shrink: 0;
    border-radius: 4px;
    background: #219653;
}
.ValidateUserFontClass {
    color: var(--main-color-white, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}
.mtb20 {
margin-top: 20px;
margin-bottom: 20px;
}
.UserValidationFontClass{
    color: var(--grays-gray-1, #333);
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.flexJustifyAlignCenter {
display: flex;
justify-content: center;
align-items: center;
}
.bannerBlockArticleClass {
display: flex;
padding: 24px;
flex-direction: column;
justify-content: center;
align-items: flex-start;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
border-radius: 2px 8px 8px 8px;
flex: none;
order: 1;
flex-grow: 1;
}
.noUnderLineClass {
text-decoration: none !important;
}
.bannerBlockArticleCollapsibleClass{
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: space-between;
padding: 16px;
gap: 24px;
width: 1467px;
height: 64px;
background: #FFFFFF;
border: 1px solid #E0E0E0;
box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
border-radius: 2px 8px 8px 8px;
flex: none;
order: 1;
flex-grow: 1;
}
.innerSpanBannerBlockArticleFontClass {
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 4px 24px;
gap: 10px;
width: 96px;
height: 32px;
border-radius: 4px;
flex: none;
order: 0;
flex-grow: 0;
}
.innerSpanFont {
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
color: #828282;
flex: none;
order: 0;
flex-grow: 0;
}

.MobileNumberClass {
    display: flex;
    width: 50%;
    height: 44px;
    padding: 12px 13px;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    background: var(--grays-blue-gray-6, #F4F6FA);
}
.noUserExistClass{
    color: var(--secondary, #EB5757);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.userExistClass {
    color: var(--success-green, #34C873);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
}
.retryClass {
    display: flex;
    height: 48px;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    border: 1px solid var(--grays-gray-4, #BDBDBD);
}
.retryFontClass {
    color: var(--grays-grey-3, #828282);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}
.createNewUserClass {
    display: flex;
    width: 186px;
    height: 48px;
    padding: 8px 56px;
    justify-content: center;
    align-items: center;
    gap: 16px;
    border-radius: 4px;
    background: #219653;
}
.createNewUserFontClass {
    color: var(--main-color-white, #FFF);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%;
}
</style>